import {leaderBoardTops} from "./config";

export default {
    name: 'AIPerf500 ChinaSC2024 List',
    columns: leaderBoardTops,
    data: [
        {
            manufacturer: 'Pengcheng Laboratory',
            site: 'Pengcheng Laboratory',
            score: '194527.50',
            conf: [
                'CloudBrainII, 512nodes, Ascend910(32G)*4096',
                'Kunpeng 920 2.6GHz x2048, ROCE 100G network',
            ],
        }, {
            manufacturer: 'Beijing Beilong Super Cloud Computing Co., Ltd.',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            score: '55543.06',
            conf: [
                '207 nodes, CPU：414 * Intel(R) Xeon(R) Platinum 8358 CPU @ 2.60GHz',
                'GPU：1656 * NVIDIA®Tesla®A800-SXM4-80GB, Network：4×200Gb Infiniband 4X HDR',
            ],
        }, {
            manufacturer: 'A Supercomputing Center',
            site: 'A Supercomputing Center',
            score: '53704.50',
            conf: [
                'Sunway MPP, Sunway SW26010pro, 50 cabinets, Sunway network',
            ],
        }, {
            manufacturer: 'LTHPC',
            site: 'A video company',
            score: '16361.28',
            conf: [
                '2100IG Cluster,276 nodes,NVIDIA Tesla T4(16GB) ×2208',
                'Intel Xeon 5218 × 552,10GbE/Intel network',
            ],
        }, {
            manufacturer: 'CASDAO',
            site: 'CASDAO SUZHOU',
            score: '14909.25',
            conf: [
                '50 nodes, CPU：100 * Intel(R) Xeon(R) Platinum 8468',
                'GPU：400 * NVIDIA®H800 80G SXM, Network：IB-1600Gb',
            ],
        }, {
            manufacturer: 'LTHPC',
            site: 'A video company',
            score: '14220.42',
            conf: [
                '4800IG Cluster,295 nodes,NVIDIA GTX 2080Ti ×2360',
                'Intel Xeon E5-2680v4 ×590 ,10GbE/Intel network',
            ],
        }, {
            manufacturer: 'LTHPC',
            site: 'A technology company',
            score: '10360.00',
            conf: [
                '4800IG Cluster,70 nodes,NVIDIA Tesla V100(32G) ×560',
                'Intel Xeon 6146 ×140, FDR IB/Mellanox network',
            ],
        }, {
            manufacturer: 'Shanhe AI Platform',
            site: 'Shanhe AI Platform',
            score: '6208.31',
            conf: [
                '50 nodes, NVIDIA A100(40GB)*400',
                'Intel Xeon Icelake Platinum 32cores, 2.6GHz, 2*200Gb/s IB HDR network',
            ],
        }, {
            manufacturer: 'Chinasoft International Technology\nServices Ltd.',
            site: 'Chinasoft International Technology\nServices Ltd.',
            score: '5070.00',
            conf: [
                'NVIDIA Tesla NVLink V100 Host,32 nodes,NVIDIA Tesla NVLinkV100(32GB)×256',
                'Intel Xeon Skylake 6151 3.0GHz×40,InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '4698.38',
            conf: [
                '66 nodes, CPU ：Intel Xeon E5-2640 v4@2.4GHz x 132',
                'NVIDIA Tesla V100 16GB x 264, Omini-Path 100Gb/s Network',
            ],
        }, {
            manufacturer: 'Tsinghua Tongfang Co.,Ltd',
            site: 'Inner Mongolia HPC Public Service\n(Qingcheng Light)',
            score: '3918.54',
            conf: [
                'TANAC2020 Cluster,72 nodes,NVIDIA Tesla PCIe V100(16GB)×288',
                'Intel Xeon Gold 6129 2.3GHz×148,InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'CASDAO',
            site: 'CASDAO SUZHOU',
            score: '3076.30',
            conf: [
                '50 nodes, CPU：100 * AMD EPYC 7542 32-Core Processor',
                'GPU：400 * NVIDIA GeForce RTX 3090, Network：IB-200Gb',
            ],
        }, {
            manufacturer: 'CASDAO',
            site: 'CASDAO SUZHOU',
            score: '2998.73',
            conf: [
                '25 nodes, CPU：50 * Intel(R) Xeon(R) Gold 6348 CPU @ 2.60GHz',
                'GPU：200 * NVIDIA A100-PCIE-40GB, Network：IB-200Gb',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '2776.55',
            conf: [
                '16 nodes, Intel Xeon Platinum 8260 @2.4GHz x 32',
                'NVIDIA Tesla V100 SXM2 32GB x 128, RoCE 100Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '2761.21',
            conf: [
                '16 nodes, Intel Xeon Platinum 8260 @2.4GHz x 32',
                'NVIDIA Tesla V100 SXM2 32GB x 128, RoCE 100Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '2236.47',
            conf: [
                '14 nodes, Intel Xeon Platinum 6248 @2.5GHz x 28',
                'NVIDIA Tesla V100 SXM2 32GB x 112, RoCE 25Gb/s network',
            ],
        }, {
            manufacturer: 'Institute of Automation, Chinese Academy of Sciences',
            site: 'Institute of Automation, Chinese Academy of Sciences',
            score: '2052.65',
            conf: [
                '28 nodes, Intel Xeon 6230 x12, Intel Xeon 5220 x4, Intel Xeon 4216 x20',
                'AMD EPYC 7742 x20, NVIDIA Tesla V100S(32GB) x118, RoCE 100Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '1969.35',
            conf: [
                '12 nodes, Intel Xeon Gold6150 v5@2.7GHz x 24',
                'NVIDIA Tesla V100 SXM2 32GB x 96, RoCE 25Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '1847.92',
            conf: [
                '12 nodes, Intel Xeon Gold 6150 v5@2.7GHz x 24',
                'NVIDIA Tesla V100 SXM2 32GB x 96, RoCE 25Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '1632.01',
            conf: [
                '14 nodes, Intel Xeon Platinum 6248 @2.5GHz x 28',
                'NVIDIA Tesla V100 SXM2 32GB x 112, RoCE 25Gb/s network',
            ],
        }, {
            manufacturer: 'A cluster',
            site: 'An university',
            score: '1608.00',
            conf: [
                '5 nodes, CPU ：10 * Intel(R) Xeon(R) Platinum 8369B CPU @ 2.90GHz',
                'GPU ：8 * NVIDIA A100-SXM4-80GB, Network ： RoCEv2 100GB/s',
            ],
        }, {
            manufacturer: 'Wegene',
            site: 'Wegene',
            score: '1350.00',
            conf: [
                'NVIDIA Tesla NVLink V100 Host,8 nodes,NVIDIA Tesla NVLink V100(32GB)×64',
                'Intel Xeon Skylake 6151 3.0GHz×40,InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '1050.06',
            conf: [
                '8 nodes, Intel Xeon Platinum 8260 @2.4GHz x 16',
                'NVIDIA Tesla V100 SXM2 32GB x 64, RoCE 25Gb/s network',
            ],
        }, {
            manufacturer: 'Sitonholy',
            site: 'Sitonholy',
            score: '974.21',
            conf: [
                'IW4210-8G,8 nodes,NVIDIA Tesla PCIe A100(40GB) x64',
                'Intel Xeon Gold 6248R 3.0GHz x16,InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '913.16',
            conf: [
                '7 nodes, Gold6150 v5@2.7GHz x 14',
                'NVIDIA Tesla V100 SXM2 32GB x 56, RoCE 25Gb/s, network',
            ],
        }, {
            manufacturer: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER | AI Cloud',
            score: '843.01',
            conf: [
                '9 nodes, Gold5218 v5@2.3GHz x 18',
                'NVIDIA Tesla V100 SXM2 32GB x 36, InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'LTHPC',
            site: 'A cloud computing company',
            score: '557.38',
            conf: [
                '2100IG Cluster, 200 nodes, NVIDIA Tesla T4(16GB) x600',
                'ARM v8.2 + Q80-3 x200, 25GbE/Mellanox',
            ],
        }, {
            manufacturer: 'Beijing ChinaHPC Technology Co., Ltd',
            site: 'AIDC',
            score: '228.30',
            conf: [
                '8 nodes, CPU: Intel Xeon Platinum 8375C Processor@2.9GHz x 16',
                'GPU: NVIDIA A100 80GB x 8, Network: InfiniBand EDR 100Gb',
            ],
        }, {
            manufacturer: 'Central South University',
            site: 'Central South University',
            score: '172.33',
            conf: [
                'Central South University Platform,1 node,NVIDIA Tesla V100(32GB)×8',
                'Intel Xeon Gold 6248 CPU 2.50GHz×2,InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'A Supercomputing Center',
            site: 'A Supercomputing Center',
            score: '147.65',
            conf: [
                '5 nodes,NVIDIA Tesla V100(32GB)×20',
                'Intel Xeon Bronze 3106 1.70 GHz×10,10GbE/Intel network',
            ],
        }, {
            manufacturer: 'NVIDIA',
            site: 'Beijing Academy of Artificial Intelligence',
            score: '134.89',
            conf: [
                'DGX 1,1 node,NVIDIA Tesla V100(32GB)×8',
                'Intel Xeon CPU E5-2698 v4 2.20GHz×2,Dual 10 GbE, 4 IB EDR network',
            ],
        }, {
            manufacturer: 'NVIDIA',
            site: 'Tsinghua University',
            score: '132.08',
            conf: [
                'NICO2,1 node,NVIDIA Tesla V100(32GB)×8',
                'Intel Xeon Gold 5218 2.30GHz×2,InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'Inspur',
            site: 'Super Scientific Software Laboratory',
            score: '129.77',
            conf: [
                '3 nodes, NVIDIA 2060 SUPER(8G)x24',
                'Intel Xeon 4214 2.2Hz x 6, Optical Fiber 10Gb/s network',
            ],
        }, {
            manufacturer: 'Lenovo Group Ltd.',
            site: 'Qinghai University',
            score: '56.12',
            conf: [
                'Lenovo ThinkSystem SD530,4 nodes,NVIDIA Tesla T4(16GB)×8',
                'Intel Xeon Gold 6242 16C 150W 2.8GHz×8,InfiniBand 100Gb/s network',
            ],
        }, {
            manufacturer: 'Beijing Beilong Super Cloud Computing Co., Ltd.',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            score: '100168.41',
            conf: [
                '878 nodes, AMD EPYC 7402 24C@2.80GHz',
                'NVIDIA®Geforce®RTX-4090-24GB, 439TB Memory',
            ],
        }, {
            manufacturer: 'Beijing Beilong Super Cloud Computing Co., Ltd.',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            score: '82088.79',
            conf: [
                '276 nodes, Intel® Xeon® Platinum 8358 CPU @ 2.60GHz',
                'NVIDIA®Tesla®A800-SXM4-80GB, 176TB Memory',
            ],
        }, {
            manufacturer: 'Beijing Beilong Super Cloud Computing Co., Ltd.',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            score: '64098.16',
            conf: [
                '246 nodes, Intel® Xeon® Sapphire Rapids 8462Y+ 32C 2.80GHz',
                'NVIDIA®Tesla®H800-SXM5-80GB, 246TB Memory',
            ],
        }, {
            manufacturer: 'A company',
            site: 'Unicom Digital Tech',
            score: '7705.68',
            conf: [
                '12 nodes, Kunpeng 920 2.6GHz',
                'Ascend910-64GB, 18TB Memory',
            ],
        },
    ].sort((a, b) => parseFloat(b.score) - parseFloat(a.score)),
}
