import {leaderBoardScores} from "./config";

export default {
    name: 'AIPerf-Inference (Throughput) ChinaSC2024 List',
    columns: leaderBoardScores,
    data: [
        {
            manufacturer: 'Xinjiang Data Computing Power and Electric Power Technology Co.,Ltd & Qingcheng.AI',
            site: ' Yiwu Cloud Silk Road Integrated Computing Center ',
            score: '74.61',
            conf: ['6 nodes, NVIDIA®H-Series - 80GB * 44', 'Intel® Xeon® Platinum 8468',]
        },
        {
            manufacturer: 'Beijing Beilong Super Cloud Computing Co., Ltd.',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            score: '30.18',
            conf: ['4 nodes, NVIDIA®H100-80GB * 32', 'Intel® Xeon® Platinum 8358 CPU @ 2.60GHz',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'Renmin University of China',
            score: '14.23',
            conf: ['2 nodes, NVIDIA®H100-80GB * 16', 'AMD EPYC 7402 24C@2.80GHz Processor',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'Tsinghua University',
            score: '12.4',
            conf: ['4 nodes, NVIDIA®A800-80GB * 8', 'AMD EPYC 7742 64-Core Processor',]
        },
        {
            manufacturer: 'Xinjiang Data Computing Power and Electric Power Technology Co.,Ltd',
            site: 'Yiwu Cloud Silk Road Integrated Computing Center',
            score: '9.48',
            conf: ['4 nodes, NVIDIA®H-Series-80GB * 32', 'Intel® Xeon® Platinum 8468',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'Xidian University',
            score: '5.69',
            conf: ['1 nodes, NVIDIA®A100-SXM4-40GB * 8', 'Intel® Xeon® Platinum 8358 CPU @ 2.60GHz',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'China University of Petroleum, Beijing',
            score: '3.26',
            conf: ['4 nodes, NVIDIA®A800-40GB * 8', 'Intel® Xeon® Platinum 8470 * 2',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'A company',
            score: '2.32',
            conf: ['1 nodes, Kunpeng 920 2.6GHz', 'Huawei Ascend910-32GB * 8',]
        },
        {
            manufacturer: 'Tianjin Tier Technology Co.，Ltd.',
            site: 'Tianjin Tier Technology Co.，Ltd.',
            score: '1.43',
            conf: ['1 nodes, NVIDIA®Geforce®RTX-4090-24GB * 10', 'Intel® Xeon® 8473C *2',]
        },
        {
            manufacturer: 'Tianjin Tier Technology Co.，Ltd.',
            site: 'Tianjin Tier Technology Co.，Ltd.',
            score: '0.98',
            conf: ['1 nodes, NVIDIA®Geforce®RTX-4090-24GB * 1', 'Intel® Xeon® 8473C *2',]
        },
    ].sort((a, b) => parseFloat(b.score) - parseFloat(a.score)),
}
