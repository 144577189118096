import {HoverCellInner} from "../components";

function makeLeaderboardConfig(scoreColumnDisplay) {
    return [
        {display: "Manufacturer", key: "manufacturer"},
        {display: "Site", key: "site"},
        {
            display: scoreColumnDisplay, key: "score", translate: (str) => {
                return <td>
                    <div className="score">
                        {str}
                    </div>
                </td>
            }
        },
        {
            display: "System Configuration", key: "conf", translate: (conf) => {
                return <td className="hover-cell">
                    {conf.map((c, cidx) => (
                        <div className="line" key={cidx}>
                            {c}
                        </div>
                    ))}

                    <HoverCellInner>
                        {conf.map((c, cidx) => (
                            <div key={cidx}>
                                {c}
                            </div>
                        ))}
                    </HoverCellInner>
                </td>
            }
        },
    ];
}

export const leaderBoardTops = makeLeaderboardConfig("AIPerf Score (Tops)")
export const leaderBoardScores = makeLeaderboardConfig("AIPerf Score")